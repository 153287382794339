import React, { useState, useCallback, useEffect, useRef, useContext, useLayoutEffect, useMemo } from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
// import ImportUpload from 'components/import-upload'
import { theme } from '../../twconfig'
import { motionTransition } from '../../util'
import { debounce } from 'lodash'
import raf from 'raf'
// import loadable from '@loadable/component'

import Icon_ES6 from 'icons/es6.svg'
import Icon_ES6_Onetone from 'icons/es6-onetone.svg'
import Icon_GraphQL from 'icons/graphql.svg'
import Icon_GraphQL_Onetone from 'icons/graphql-onetone.svg'
import Icon_Gatsby from 'icons/gatsby.svg'
import Icon_Gatsby_Onetone from 'icons/gatsby-onetone.svg'
import Icon_Nuxt from 'icons/nuxt.svg'
import Icon_Nuxt_Onetone from 'icons/nuxt-onetone.svg'
import Icon_SLS from 'icons/serverless.svg'
import Icon_SLS_Onetone from 'icons/serverless-onetone.svg'
import Icon_Bootstrap from 'icons/bootstrap.svg'
import Icon_Bootstrap_Onetone from 'icons/bootstrap-onetone.svg'
import Icon_React from 'icons/react.svg'
import Icon_React_Onetone from 'icons/react-onetone.svg'
import Icon_Sass from 'icons/sass.svg'
import Icon_Sass_Onetone from 'icons/sass-onetone.svg'
import Icon_Node from 'icons/nodejs-icon.svg'
import Icon_Node_Onetone from 'icons/nodejs-icon-onetone.svg'
import Icon_WP from 'icons/wordpress-light.svg'
import Icon_WP_Onetone from 'icons/wordpress-onetone.svg'
import Icon_Woocommerce from 'icons/woocommerce.svg'
import Icon_Woocommerce_Onetone from 'icons/woocommerce-onetone.svg'
import Icon_Laravel from 'icons/laravel.svg'
import Icon_Laravel_Onetone from 'icons/laravel-onetone.svg'
import Icon_Vue from 'icons/vue.svg'
import Icon_Vue_Onetone from 'icons/vue-onetone.svg'
import Icon_Webpack from 'icons/webpack.svg'
import Icon_Webpack_Onetone from 'icons/webpack-onetone.svg'
import Icon_Tailwind from 'icons/tailwindcss-icon.svg'
import Icon_Tailwind_Onetone from 'icons/tailwindcss-icon-onetone.svg'
import Icon_JamStack from 'icons/jamstack.svg'
import Icon_JamStack_Onetone from 'icons/jamstack-onetone.svg'
import Icon_Jest from 'icons/jest.svg'
import Icon_Jest_Onetone from 'icons/jest-onetone.svg'
import { motion, useSpring } from 'framer-motion'
import { useMediaQueryContext } from '../../context/media'
import viewportMotion from '../viewport-motion'
import { SquaresBackgroundSvg } from '../../templates/home'
// import TechsGridDesktop from './techs-grid-desktop'
import { emptyVariants } from '../../util'
import TechsGridDesktop from '../techs-grid-desktop'
import TextTransition from '../text-transition'
import { Link } from 'gatsby'
// const TechsGridDesktop = loadable(() => import(`./techs-grid-desktop`))

const TechLogoBase = ({svg, ...props}) => (
  <svg {...props}>
    <use xlinkHref={ `#${svg.id}` } ></use>
  </svg>
)

export const TechLogo = styled(TechLogoBase)``

export const StackCell = styled(motion.li)``

export const StackGrid = styled(viewportMotion.ul)`
  ${tw`flex flex-row flex-wrap`}

  ${TechLogo} {
    display: block;
    position: absolute;
    width: 60%;
    height: 60%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: ${theme.colors.gray[`500`]};
    fill: currentColor;
  }
  
  &.entered {
    ${TechLogo} {
      + ${TechLogo} {
        transition-delay: 0.6s;
        transition: all .6s ease-in-out;
        opacity: 0;
      }
    }
  }
  
  ${StackCell} {
    display: block;
    position: relative;
    ${tw`w-1/3`}
    padding-top: 33.33%;

    @media (min-width: ${theme.screens.sm}) {
      width: 25%;
      padding-top: 25%;
    }

    @media (min-width: ${theme.screens.lg}) {
      width: 11.11%;
      padding-top: 11.11%;
    }
    
    &.double {
      ${tw`w-2/3`}
      
      @media (min-width: ${theme.screens.sm}) {
        width: 50%;
      }

      @media (min-width: ${theme.screens.lg}) {
        width: 22.22%;
      }
    }

    &.br {
      border-right: 1px solid ${theme.colors.gray[`600`]};
    }

    &.bb {
      border-bottom: 1px solid ${theme.colors.gray[`600`]};
    }

    &:hover {
      ${TechLogo} + ${TechLogo} {
        opacity: 1;
      }
    }
  }
`

const listDesktopVariants = {
  visible: {
    transition: {
      staggerChildren: .15,
    }
  },
  hidden: {}
}

const visibleVariant = { opacity: 1, x: 0, y: 0 }

const listItemDesktopVariants =  {
  visible: visibleVariant,
  hidden: {
    opacity: 0,
    y: 50
  },
}

const dispatchResize = debounce( () => window.dispatchEvent(new Event(`resize`)), 50 )

const OurStack = props => {
  const breakpoints = useMediaQueryContext()
  const [collapsed, setCollapsed] = useState(false)
  const [rowHeight, setRowHeight] = useState(0)
  const [togglerVisibility, setTogglerVisibility] = useState(false)
  const visibleRows = useMemo(() => breakpoints.lg ? 4 : 3, [breakpoints.lg])
  
  const firstCell = useRef()
  const collapsable = useRef()
  const collapsableHeight = useSpring(0, motionTransition)

  const updateRowHeight = useCallback(() => {
    if ( firstCell.current ) {
      const clientRect = firstCell.current.getBoundingClientRect()
      setRowHeight(clientRect.height)
    }
  }, [])

  const firstCellMounted = useCallback(ref => {
    if ( ! ref ) {
      return
    }
    firstCell.current = ref
    updateRowHeight()
  }, [updateRowHeight])

  useLayoutEffect(() => { updateRowHeight() }, [updateRowHeight, breakpoints])

  useLayoutEffect(() => {
    if (rowHeight) {
      const elemHeight = Math.ceil(rowHeight * visibleRows)

      if ( collapsed ) {
        collapsableHeight.set(elemHeight)
      }

      setTogglerVisibility(elemHeight < collapsable.current.scrollHeight)
    }

  }, [collapsableHeight, collapsed, rowHeight, visibleRows])

  useEffect(() => {
    if (collapsed) {
      collapsableHeight.set( collapsable.current.scrollHeight )
    } else {
      collapsableHeight.set( rowHeight * visibleRows)
    }
    
  }, [collapsableHeight, collapsed, rowHeight, visibleRows])

  useEffect(() => collapsableHeight.onChange(() => {
    raf(dispatchResize)
  }), [collapsableHeight])


  // if ( breakpoints.lg ) {
  //   TechsGridDesktop.load().then(() => {
  //     updateRowHeight()
  //   })
  // }

  return (
    <div className="container" {...props}>
      <motion.div
        ref={collapsable}
        className="overflow-hidden -mx-5 lg:mx-0"
        style={{ height: collapsableHeight }}
      >
        {breakpoints.lg && (
          <TechsGridDesktop variants={listDesktopVariants} delayPerPixel={.0005} firstCellRef={firstCellMounted} />
        )}

        {! breakpoints.lg && (
          <StackGrid className="" inViewportOptions={{ threshold: .1 }}>
            <StackCell variants={listItemDesktopVariants} ref={firstCellMounted} className="br">
              <Link to="/glossary/react/">
                <TechLogo svg={Icon_React_Onetone} />
                <TechLogo svg={Icon_React} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/wordpress/">
                <TechLogo svg={Icon_WP_Onetone} />
                <TechLogo svg={Icon_WP} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="bb"></StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb"></StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/sass/">
                <TechLogo svg={Icon_Sass_Onetone} />
                <TechLogo svg={Icon_Sass} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/gatsbyjs/">
                <TechLogo svg={Icon_Gatsby_Onetone} />
                <TechLogo svg={Icon_Gatsby} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/serverless/">
                <TechLogo svg={Icon_SLS_Onetone} />
                <TechLogo svg={Icon_SLS} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/vue/">
                <TechLogo svg={Icon_Vue_Onetone} />
                <TechLogo svg={Icon_Vue} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/graphql/">
                <TechLogo svg={Icon_GraphQL_Onetone} />
                <TechLogo svg={Icon_GraphQL} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/nodejs/">
                <TechLogo svg={Icon_Node_Onetone} />
                <TechLogo svg={Icon_Node} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="double br bb">
              <Link to="/glossary/jamstack/">
                <TechLogo svg={Icon_JamStack_Onetone} />
                <TechLogo svg={Icon_JamStack} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br"></StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/bootstrap/">
                <TechLogo svg={Icon_Bootstrap_Onetone} />
                <TechLogo svg={Icon_Bootstrap} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/tailwindcss/">
                <TechLogo svg={Icon_Tailwind_Onetone} />
                <TechLogo svg={Icon_Tailwind} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/laravel/">
                <TechLogo svg={Icon_Laravel_Onetone} />
                <TechLogo svg={Icon_Laravel} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/woocommerce/">
                <TechLogo svg={Icon_Woocommerce_Onetone} />
                <TechLogo svg={Icon_Woocommerce} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br bb">
              <Link to="/glossary/es6/">
                <TechLogo svg={Icon_ES6_Onetone} />
                <TechLogo svg={Icon_ES6} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br">
              <Link to="/glossary/nuxt/">
                <TechLogo svg={Icon_Nuxt_Onetone} />
                <TechLogo svg={Icon_Nuxt} />
              </Link>
            </StackCell>
            <StackCell variants={listItemDesktopVariants} className="br">
              <Link to="/glossary/webpack/">
                <TechLogo svg={Icon_Webpack_Onetone} />
                <TechLogo svg={Icon_Webpack} />
              </Link>
            </StackCell>
          </StackGrid>
        )}
      </motion.div>

      {togglerVisibility && (
        <div className="text-center mt-4">
          <button
            className="inline-block align-top lowercase"
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? `View less` : `View more`}
          </button>
        </div>
      )}
    </div>
  )
}

const OurStackSection = ({ id, data, ...props }) => (
  <section
    id="our-stack"
    className="py-12"
    data-loadable-fallback={`section-${id}`}
  >
    <SquaresBackgroundSvg
      className="mb-8"
    >
      <h2 className="section-title">
        <viewportMotion.span
          variants={emptyVariants}
          className="inline-block bg-black px-4"
        >
          <TextTransition text="Our Stack" />
        </viewportMotion.span>
      </h2>
    </SquaresBackgroundSvg>

    <OurStack data={data} />
  </section>
)

export default OurStackSection
