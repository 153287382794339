import React, { forwardRef, useRef, useLayoutEffect, useEffect, useCallback } from 'react'
import { StackGrid, StackCell, TechLogo } from './home/our-stack'
import Icon_ES6 from 'icons/es6.svg'
import Icon_ES6_Onetone from 'icons/es6-onetone.svg'
import Icon_GraphQL from 'icons/graphql.svg'
import Icon_GraphQL_Onetone from 'icons/graphql-onetone.svg'
import Icon_Gatsby from 'icons/gatsby.svg'
import Icon_Gatsby_Onetone from 'icons/gatsby-onetone.svg'
import Icon_Nuxt from 'icons/nuxt.svg'
import Icon_Nuxt_Onetone from 'icons/nuxt-onetone.svg'
import Icon_SLS from 'icons/serverless.svg'
import Icon_SLS_Onetone from 'icons/serverless-onetone.svg'
import Icon_Bootstrap from 'icons/bootstrap.svg'
import Icon_Bootstrap_Onetone from 'icons/bootstrap-onetone.svg'
import Icon_React from 'icons/react.svg'
import Icon_React_Onetone from 'icons/react-onetone.svg'
import Icon_Sass from 'icons/sass.svg'
import Icon_Sass_Onetone from 'icons/sass-onetone.svg'
import Icon_Node from 'icons/nodejs-icon.svg'
import Icon_Node_Onetone from 'icons/nodejs-icon-onetone.svg'
import Icon_WP from 'icons/wordpress-light.svg'
import Icon_WP_Onetone from 'icons/wordpress-onetone.svg'
import Icon_Woocommerce from 'icons/woocommerce.svg'
import Icon_Woocommerce_Onetone from 'icons/woocommerce-onetone.svg'
import Icon_Laravel from 'icons/laravel.svg'
import Icon_Laravel_Onetone from 'icons/laravel-onetone.svg'
import Icon_Vue from 'icons/vue.svg'
import Icon_Vue_Onetone from 'icons/vue-onetone.svg'
import Icon_Webpack from 'icons/webpack.svg'
import Icon_Webpack_Onetone from 'icons/webpack-onetone.svg'
import Icon_Tailwind from 'icons/tailwindcss-icon.svg'
import Icon_Tailwind_Onetone from 'icons/tailwindcss-icon-onetone.svg'
import Icon_JamStack from 'icons/jamstack.svg'
import Icon_JamStack_Onetone from 'icons/jamstack-onetone.svg'
import { motionTransition } from '../util'
import { Link } from 'gatsby'
// import Icon_Jest from 'icons/jest.svg'
// import Icon_Jest_Onetone from 'icons/jest-onetone.svg'


const visibleVariant = { opacity: 1, x: 0, y: 0 }

const listItemDesktopVariants =  {
  visible(delayRef) {
    if (! delayRef) {
      return visibleVariant
    } 
    
    return {
      ...visibleVariant,
      transition: {
        delay: delayRef.current,
      }
    }
  },
  hidden: {
    opacity: 0,
    // y: 50
  },
}

const itemTransition = {
  ...motionTransition,
  stiffness: 5,
  mass: 1,
}


const cells = [
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/react/">
      <TechLogo svg={Icon_React_Onetone} />
      <TechLogo svg={Icon_React} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/wordpress/">
      <TechLogo svg={Icon_WP_Onetone} />
      <TechLogo svg={Icon_WP} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="bb br double"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/sass/">
      <TechLogo svg={Icon_Sass_Onetone} />
      <TechLogo svg={Icon_Sass} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/gatsbyjs/">
      <TechLogo svg={Icon_Gatsby_Onetone} />
      <TechLogo svg={Icon_Gatsby} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="bb br double"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/nodejs/">
      <TechLogo svg={Icon_Node_Onetone} />
      <TechLogo svg={Icon_Node} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="bb br"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/vue/">
      <TechLogo svg={Icon_Vue_Onetone} />
      <TechLogo svg={Icon_Vue} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/graphql/">
      <TechLogo svg={Icon_GraphQL_Onetone} />
      <TechLogo svg={Icon_GraphQL} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="bb br"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="bb br"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="double br bb">
    <Link to="/glossary/jamstack/">
      <TechLogo svg={Icon_JamStack_Onetone} />
      <TechLogo svg={Icon_JamStack} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="bb br"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="bb br"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/bootstrap/">
      <TechLogo svg={Icon_Bootstrap_Onetone} />
      <TechLogo svg={Icon_Bootstrap} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="bb br"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br">
    <Link to="/glossary/webpack/">
      <TechLogo svg={Icon_Webpack_Onetone} />
      <TechLogo svg={Icon_Webpack} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="bb br"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br">
    <Link to="/glossary/nuxt/">
      <TechLogo svg={Icon_Nuxt_Onetone} />
      <TechLogo svg={Icon_Nuxt} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="bb br"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/serverless/">
      <TechLogo svg={Icon_SLS_Onetone} />
      <TechLogo svg={Icon_SLS} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/es6/">
      <TechLogo svg={Icon_ES6_Onetone} />
      <TechLogo svg={Icon_ES6} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br"></StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/tailwindcss/">
      <TechLogo svg={Icon_Tailwind_Onetone} />
      <TechLogo svg={Icon_Tailwind} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/laravel/">
      <TechLogo svg={Icon_Laravel_Onetone} />
      <TechLogo svg={Icon_Laravel} />
    </Link>
  </StackCell> ),
  forwardRef( (props, ref) => <StackCell {...props} ref={ref} className="br bb">
    <Link to="/glossary/woocommerce/">
      <TechLogo svg={Icon_Woocommerce_Onetone} />
      <TechLogo svg={Icon_Woocommerce} />
    </Link>
  </StackCell> ),
]

const TechsGridDesktop = forwardRef( ({ firstCellRef, delayPerPixel, ...props }, ref) => {
  const originOffset = useRef({ left: 0 })

  return (
    <StackGrid ref={ref} className="" {...props}>
      {cells.map( (Cell, idx) => {
        const props = {}
        
        const delayRef = useRef(0);
        const offset = useRef({ left: 0 });
        const ref = useRef();

        const handleRef = useCallback((elem, idx) => {
          if (! elem) {
            return
          }

          if (idx === 0) {
            firstCellRef(elem)
          }
          
          ref.current = elem
          offset.current = {
            // top: element.offsetTop,
            left: elem.offsetLeft
          };
          // if ( props.ref = firstCellRef)
        }, [])
  
        useLayoutEffect(() => {
          const element = ref.current;
          if (!element) return;
      
      
          // if (i === originIndex) {
          //   originOffset.current = offset.current;
          // }
        }, []);
  
        useEffect(() => {
          const dx = Math.abs(offset.current.left - originOffset.current.left);
          // const dy = Math.abs(offset.current.top - originOffset.current.top);
          // const d = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
          const delay = dx * delayPerPixel
          delayRef.current = dx * delayPerPixel;
        }, [idx]);
  
        props.custom = delayRef
  
        return (
          <Cell ref={elem => handleRef(elem, idx)} key={idx} {...props} variants={listItemDesktopVariants} transition={itemTransition} />
        )
      } )}
    </StackGrid>
  )
})

export default TechsGridDesktop
